import React from "react";

import { Flex, Link, Section } from "@radix-ui/themes";
import TitleText from "../components/TitleText";
import Text from "../components/Text";
import SpacedText from "../components/SpacedText";
import { useResetScrollOnMount } from "../components/ScrollArea";

export default function FaqPage() {
  useResetScrollOnMount();

  return (
    <Flex grow={"1"} direction={"column"} width={"100%"} gap={"3"} p={"3"}>
      <Section size={"1"}>
        <TitleText align={"center"}>Frequently Asked Questions</TitleText>
      </Section>
      <QASection>
        <Question>Do you have a registry?</Question>
        <Text>
          You have already given us the best gift we could ask for, by being in
          our lives. If you insist on doing something more, please consider
          making a donation to a cause we care about. Here are some suggestions:
        </Text>
        <Link target="_blank" href="https://theintersection.org/">
          Intersection for the Arts
        </Link>
        <Link target="_blank" href="https://edenhousing.org/">
          Eden Housing
        </Link>
        <Link target="_blank" href="https://hiphousing.org/">
          HIP Housing
        </Link>
        <Link target="_blank" href="https://www.shfb.org/">
          Second Harvest of Silicon Valley
        </Link>
        <Link target="_blank" href="https://schoolsforsalone.org/">
          Schools for Salone
        </Link>
        <Text>
          While we would be thrilled for you to invest in our community, these
          causes are not unique to our area; we would be just as happy if you
          decided to donate to similar organizations in your own community!
        </Text>
      </QASection>
      <QASection>
        <Question>Are my children invited?</Question>
        <Text>
          Yes, we would love to have them celebrate with us! We want them to
          have fun too, so please let us know if you think of anything that
          would make the event enjoyable to your child. If you&apos;re on the
          fence, please reach out to us; we&apos;d love to hear what you&apos;re
          thinking, and try to address your concerns.
        </Text>
        <Text>
          Please be aware that&nbsp;
          <Link
            target="_blank"
            href="https://www.ots.ca.gov/child-passenger-safety/"
          >
            California has car seat laws
          </Link>
          , and that they apply to taxis and rideshares as well.
        </Text>
      </QASection>
      <QASection>
        <Question>What&apos;s the dress code?</Question>
        <Text>
          Wear whatever you&apos;ll enjoying spending the evening in! Here are
          some things to consider:
          <ul>
            <li>
              The ceremony and dinner will be on grass, dancing will be indoors.
            </li>
            <li>We&apos;ll be taking photos on a sandy beach.</li>
            <li>
              The lows around that time of year will be below 50F (10C), and can
              get as low as 27F (-3C).
            </li>
            <li>
              The highs will typically be around 71F (22C), though can get as
              warm as 85F (29C).
            </li>
            <li>Helen will be wearing a white wedding dress.</li>
            <li>Solomon will be wearing a tuxedo.</li>
          </ul>
        </Text>
      </QASection>
      <QASection>
        <Question>What&apos;s the best way to get to the venue?</Question>
        <Flex direction="column" gap="3">
          <Text>
            We are offering door-to-door shuttles between&nbsp;
            <Link
              target="_blank"
              href="https://maps.app.goo.gl/mA9fwZuj1CVKdEzf8"
            >
              the venue
            </Link>
            &nbsp;and anywhere within&nbsp;
            <Link
              target="_blank"
              href="https://maps.app.goo.gl/yqBQP14YjxwWJ3Tx8"
            >
              South Lake Tahoe
            </Link>
            &nbsp;or&nbsp;
            <Link
              target="_blank"
              href="https://maps.app.goo.gl/YUYgJdc9kTcmABkr7"
            >
              Stateline
            </Link>
            , and we&apos;ll also do our best to accommodate locations outside
            of those limits. If you&apos;d like to use the shuttles, please note
            the location you&apos;d like to be picked up and dropped off on your
            RSVP. We will follow up with your pick up and drop off times after
            we&apos;ve received everyone&apos;s responses
          </Text>
          <Text>
            We&apos;ll have shuttles on standby for anyone who needs to leave
            early.
          </Text>
          <Text>
            We believe taking the shuttles will be the best experience for most
            people, but there is parking at{" "}
            <Link
              target="_blank"
              href="https://maps.app.goo.gl/mA9fwZuj1CVKdEzf8"
            >
              the venue
            </Link>
            , should you choose to drive.
          </Text>
        </Flex>
      </QASection>
      <QASection>
        <Question>What&apos;s for dinner?</Question>
        <Text>
          <ul>
            <li>
              Berry Celebration Salad w/ champagne vinaigrette; cheese on the
              side
            </li>
            <li>Whiskey Fire Tri Tip w/ blue cheese horseradish</li>
            <li>Almond Encrusted Halibut w/ tangy lemon vinaigrette</li>
            <li>Roasted Veggies</li>
            <li>Warm Quinoa</li>
          </ul>
        </Text>
      </QASection>
      <QASection>
        <Question>
          Will there be meal options for guests with dietary restrictions?
        </Question>
        <Text>
          Please note any dietary restrictions when you RSVP. We will have a
          family-style meal, but our caterer will prepare individual portions of
          alternative items for anyone with dietary restrictions.
        </Text>
      </QASection>
      {/* <QASection> */}
      {/*  <Question>Do you have a gift registry?</Question> */}
      {/*  <Box> */}
      {/*    No; your presence at our celebration is the best gift we could receive. */}
      {/*    If you feel compelled give something extra, please consider making a donation to support a cause we care about. */}
      {/*    Here are some suggestions: */}
      {/*  </Box> */}
      {/* </QASection> */}
    </Flex>
  );
}

function QASection({ children }) {
  return (
    <Section size={"1"}>
      <Flex direction={"column"} gap={"3"} width={"100%"}>
        {children}
      </Flex>
    </Section>
  );
}

function Question({ children }) {
  return <SpacedText weight={"bold"}>{children}</SpacedText>;
}
