import { ScrollArea as RadixScrollArea } from "@radix-ui/themes";
import React, { createContext, useRef, useEffect, useContext } from "react";

export const ScrollContext = createContext({});

export default function ScrollArea(props) {
  const scrollAreaRef = useRef(null);

  return (
    <ScrollContext.Provider value={{ scrollAreaRef }}>
      <RadixScrollArea ref={scrollAreaRef} {...props} />
    </ScrollContext.Provider>
  );
}

export function useResetScrollOnMount() {
  const { scrollAreaRef } = useContext(ScrollContext);
  useEffect(() => {
    scrollAreaRef.current?.scroll(0, 0);
    // empty deps are intentional; we only want to reset on mount
  }, []);
}
