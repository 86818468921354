export async function parseJsonResponse(response) {
  const { status } = response;
  let responseBody = "";
  try {
    responseBody = await response.json();
  } catch {
    // ignore JSON parsing errors
  }

  if (response.ok) {
    return responseBody;
  } else if (status >= 400 && status < 500) {
    const message = responseBody?.message || response.statusText;
    const error = new Error(message);
    error.status = status;
    throw error;
  } else {
    throw new Error("An unexpected error occurred");
  }
}
