import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { PartyContext } from "../components/PartyContext";
import {
  Box,
  Dialog,
  Checkbox,
  Flex,
  Link,
  RadioGroup,
  Section,
  TextArea,
  Button,
  VisuallyHidden,
} from "@radix-ui/themes";
import Text from "../components/Text";
import SpacedText from "../components/SpacedText";
import MinorText from "../components/MinorText";
import PaddedCard from "../components/PaddedCard";
import TitleText from "../components/TitleText";
import { Pencil2Icon, PlusIcon } from "@radix-ui/react-icons";
import TextInput from "../components/TextInput";

export default function RsvpPage() {
  return (
    <Flex direction={"column"} align={"stretch"} width={"100%"} gap={"5"}>
      <AttendeesCard />
      <ShuttleCard />
      <OtherAccomodationsCard />
    </Flex>
  );
}

function AttendeesCard() {
  const {
    party: { guests, maxGuests },
  } = useContext(PartyContext);

  return (
    <PaddedCard>
      <Section size={"1"}>
        <TitleText>Attendees</TitleText>
      </Section>
      <Flex direction={"column"} gap={"5"}>
        {guests.map((guest) => (
          <Guest key={guest.id} {...guest} />
        ))}
        {guests.length < maxGuests && (
          <Flex direction={"column"} gap={"3"}>
            <Text>Please add any other guests who will be attending:</Text>
            <GuestAdder />
          </Flex>
        )}
      </Flex>
    </PaddedCard>
  );
}

function Guest({ id, name, isAttending, dietaryRestrictions }) {
  const { updateGuest, removeGuest } = useContext(PartyContext);

  const handleNameChange = useCallback(
    (name) => {
      updateGuest({ id, name });
    },
    [id, updateGuest],
  );

  const handleDeleteGuest = useCallback(() => {
    removeGuest(id);
  }, [id, removeGuest]);

  const handleIsAttendingChange = useCallback(
    (value) => {
      updateGuest({
        id,
        isAttending: JSON.parse(value),
      });
    },
    [id, updateGuest],
  );

  const handleDietaryRestrictionsChange = useCallback(
    (value) => {
      updateGuest({
        id,
        dietaryRestrictions: value,
      });
    },
    [id, updateGuest],
  );

  return (
    <Flex direction="column" gap={"3"}>
      <Box>
        <GuestEditor
          name={name}
          onChange={handleNameChange}
          onDelete={handleDeleteGuest}
        />
      </Box>
      <Flex gap={"1"} direction={"column"}>
        <MinorText>Response</MinorText>
        <Flex gap={"3"} asChild>
          <RadioGroup.Root
            onValueChange={handleIsAttendingChange}
            value={JSON.stringify(isAttending)}
          >
            <Flex gap={"1"} asChild>
              <Text as={"label"}>
                <RadioGroup.Item value={"true"} />
                Accepts
              </Text>
            </Flex>
            <Flex gap={"1"} asChild>
              <Text as={"label"}>
                <RadioGroup.Item value={"false"} />
                Declines
              </Text>
            </Flex>
          </RadioGroup.Root>
        </Flex>
      </Flex>
      <Flex gap={"1"} direction={"column"}>
        <MinorText>Dietary Restrictions</MinorText>
        <Box>
          <DietaryRestrictionsEditor
            value={dietaryRestrictions}
            onChange={handleDietaryRestrictionsChange}
          />
        </Box>
      </Flex>
    </Flex>
  );
}

function DietaryRestrictionsEditor({ value, onChange }) {
  const [open, setOpen] = useState(false);
  const handleModalSubmit = useCallback(
    (newValue) => {
      onChange(newValue);
      setOpen(false);
    },
    [onChange],
  );

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Flex>
        <Dialog.Trigger asChild>
          <Flex gap={"3"} align={"center"} className={"clickable"}>
            <Text style={{ whiteSpace: "pre-line" }}>{value || "None"}</Text>
            <Pencil2Icon
              width={"16px"}
              height={"16px"}
              color={"gray"}
              style={{ minWidth: "16px" }}
            />
          </Flex>
        </Dialog.Trigger>
      </Flex>
      <DietaryRestrictionsModal value={value} onSubmit={handleModalSubmit} />
    </Dialog.Root>
  );
}

function DietaryRestrictionsModal({ value, onSubmit }) {
  const { checkboxes, other } = useMemo(() => {
    const tokens = value.split("; ");
    const checkboxes = {
      "No dairy": false,
      "No egg": false,
      "No nuts": false,
      "No seafood": false,
      "No soy": false,
      "No gluten": false,
      "No beef": false,
      "No meat": false,
      Vegan: false,
      Kosher: false,
      Halal: false,
      "Children's meal": false,
      "No meal required": false,
    };
    const unknownTokens = [];
    for (const token of tokens) {
      if (checkboxes[token] != null) checkboxes[token] = true;
      else unknownTokens.unshift(token);
    }

    return { checkboxes, other: unknownTokens.join("; ").trim() };
  }, [value]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const { other, ...selected } = Object.fromEntries(
        new FormData(event.target).entries(),
      );
      const tokenArray = Object.keys(selected);
      const trimmedOther = other.trim();
      if (trimmedOther) tokenArray.push(trimmedOther);
      onSubmit(tokenArray.join("; "));
    },
    [onSubmit],
  );

  return (
    <Dialog.Content>
      <Dialog.Title>Edit Dietary Restrictions</Dialog.Title>
      <Flex direction={"column"} asChild gap={"3"}>
        <form onSubmit={handleSubmit}>
          <Text>Select all that apply:</Text>
          <Flex gap={"3"} wrap={"wrap"}>
            {Object.entries(checkboxes).map(([label, isChecked]) => (
              <DietaryRestrictionChoice
                key={label}
                value={label}
                defaultChecked={isChecked}
              />
            ))}
          </Flex>
          <TextArea
            placeholder={"Other dietary restrictions"}
            defaultValue={other}
            name={"other"}
          />
          <Button className={"clickable"}>
            Update
            <VisuallyHidden>
              <input type={"submit"} tabIndex={-1} />
            </VisuallyHidden>
          </Button>
        </form>
      </Flex>
    </Dialog.Content>
  );
}

function DietaryRestrictionChoice({ value, defaultChecked }) {
  return (
    <Flex gap={"1"} asChild>
      <Text as={"label"}>
        <Checkbox name={value} value={"true"} defaultChecked={defaultChecked} />
        {value}
      </Text>
    </Flex>
  );
}

function ShuttleCard() {
  const {
    party: { id, shuttleOptions: _shuttleOptions },
    updateParty,
  } = useContext(PartyContext);

  const [shuttleOptions, setShuttleOptions] = useState(_shuttleOptions);
  const { pickUpLocation, dropOffLocation } = shuttleOptions;
  const [wantsShuttle, setWantsShuttle] = useState(
    !!(pickUpLocation.trim() || dropOffLocation.trim()),
  );
  const [sameDropOff, setSameDropOff] = useState(
    pickUpLocation.trim() === dropOffLocation.trim(),
  );
  const handleWantsShuttleChange = useCallback((nextWantsShuttle) => {
    const parsedNextWantsShuttle = JSON.parse(nextWantsShuttle);
    setWantsShuttle(parsedNextWantsShuttle);
    if (!parsedNextWantsShuttle) {
      setShuttleOptions({
        pickUpLocation: "",
        dropOffLocation: "",
      });
    }
  }, []);

  const handlePickUpChange = useCallback(
    (event) => {
      setShuttleOptions(({ dropOffLocation }) => {
        const pickUpLocation = event.target.value;
        return {
          pickUpLocation,
          dropOffLocation: sameDropOff ? pickUpLocation : dropOffLocation,
        };
      });
    },
    [sameDropOff],
  );

  const handleSameDropOffChange = useCallback((nextSameDropOff) => {
    if (nextSameDropOff) {
      setShuttleOptions(({ pickUpLocation }) => {
        return {
          pickUpLocation,
          dropOffLocation: pickUpLocation,
        };
      });
    }
    setSameDropOff(nextSameDropOff);
  }, []);

  const handleDropOffChange = useCallback((event) => {
    setShuttleOptions((shuttleOptions) => {
      return {
        ...shuttleOptions,
        dropOffLocation: event.target.value,
      };
    });
  }, []);

  useEffect(() => {
    updateParty({ id, shuttleOptions });
  }, [id, shuttleOptions, updateParty]);

  return (
    <PaddedCard>
      <Section size={"1"}>
        <TitleText>Shuttle</TitleText>
      </Section>
      <Flex direction={"column"} gap={"3"}>
        <Flex direction={"column"} gap={"1"}>
          <Text>
            We are offering door-to-door shuttle service from&nbsp;
            <Link
              target="_blank"
              href="https://maps.app.goo.gl/yqBQP14YjxwWJ3Tx8"
            >
              South Lake Tahoe
            </Link>
            {" and "}
            <Link
              target="_blank"
              href="https://maps.app.goo.gl/YUYgJdc9kTcmABkr7"
            >
              Stateline
            </Link>
            . Would you like us to arrange transportation to/from the venue for
            you?
          </Text>
          <Flex gap={"3"} asChild>
            <RadioGroup.Root
              value={JSON.stringify(wantsShuttle)}
              onValueChange={handleWantsShuttleChange}
            >
              <Flex gap={"1"} asChild>
                <Text as={"label"}>
                  <RadioGroup.Item value={"true"} />
                  Yes
                </Text>
              </Flex>
              <Flex gap={"1"} asChild>
                <Text as={"label"}>
                  <RadioGroup.Item value={"false"} />
                  No
                </Text>
              </Flex>
            </RadioGroup.Root>
          </Flex>
        </Flex>
        {wantsShuttle && (
          <>
            <Flex direction={"column"} gap={"1"}>
              <MinorText>Pick Up Address</MinorText>
              <TextArea onChange={handlePickUpChange} value={pickUpLocation} />
            </Flex>
            <Flex direction={"column"} gap={"1"}>
              <MinorText>Drop Off Address</MinorText>
              <Flex gap={"1"} asChild>
                <Text as={"label"}>
                  <Checkbox
                    checked={sameDropOff}
                    onCheckedChange={handleSameDropOffChange}
                  />
                  Same as pick up
                </Text>
              </Flex>
              {!sameDropOff && (
                <TextArea
                  value={dropOffLocation}
                  onChange={handleDropOffChange}
                />
              )}
            </Flex>
          </>
        )}
      </Flex>
    </PaddedCard>
  );
}

function OtherAccomodationsCard() {
  const {
    party: { id, otherAccommodations },
    updateParty,
  } = useContext(PartyContext);

  const handleChange = useCallback(
    (event) => {
      updateParty({ id, otherAccommodations: event.target.value });
    },
    [id, updateParty],
  );

  return (
    <PaddedCard>
      <Flex gap={"1"} direction={"column"}>
        <Text>
          Please let us know if there&apos;s anything else we can do to make the
          event more enjoyable for you:
        </Text>
        <TextArea
          value={otherAccommodations}
          onChange={handleChange}
          placeholder={"Other requests"}
        />
      </Flex>
    </PaddedCard>
  );
}

function GuestEditorContent({
  name,
  onSubmit,
  onDelete,
  submitText,
  titleText,
}) {
  const [canSubmit, setCanSubmit] = useState(!!name);
  const handleInputChange = useCallback((event) => {
    setCanSubmit(!!event.target.value);
  }, []);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const { name } = Object.fromEntries(new FormData(event.target).entries());
      onSubmit(name);
    },
    [onSubmit],
  );

  return (
    <Dialog.Content>
      <Dialog.Title>{titleText}</Dialog.Title>
      <Flex gap={"3"} asChild direction={"column"}>
        <form onSubmit={handleSubmit}>
          <TextInput
            labelText={"Name"}
            inputName={"name"}
            placeholder={"Enter name"}
            defaultValue={name}
            onChange={handleInputChange}
          />
          <Flex gap={"1"}>
            <Button className={"clickable"} disabled={!canSubmit}>
              {submitText}
              <VisuallyHidden>
                <input type={"submit"} tabIndex="-1" disabled={!canSubmit} />
              </VisuallyHidden>
            </Button>
            {onDelete && (
              <Button
                className={"clickable"}
                variant={"outline"}
                color={"red"}
                onClick={onDelete}
              >
                Delete Guest
              </Button>
            )}
          </Flex>
        </form>
      </Flex>
    </Dialog.Content>
  );
}

function GuestEditor({ name, onChange, onDelete }) {
  const [open, setOpen] = useState(false);
  const handleSubmit = useCallback(
    (name) => {
      onChange(name);
      setOpen(false);
    },
    [onChange],
  );
  const handleDelete = useCallback(() => {
    onDelete();
    setOpen(false);
  }, [onDelete]);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Flex>
        <Dialog.Trigger asChild>
          <Flex gap={"3"} align={"center"} className={"clickable"}>
            <SpacedText size={"5"}>{name}</SpacedText>
            <Pencil2Icon
              width={"20px"}
              height={"20px"}
              color={"gray"}
              style={{ minWidth: "20px" }}
            />
          </Flex>
        </Dialog.Trigger>
      </Flex>
      <GuestEditorContent
        titleText={"Edit Guest"}
        name={name}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        submitText={"Update Name"}
      />
    </Dialog.Root>
  );
}

function GuestAdder() {
  const {
    addGuest,
    party: { id },
  } = useContext(PartyContext);

  const [open, setOpen] = useState(false);
  const handleSubmit = useCallback(
    (name) => {
      addGuest(id, { name, isAttending: true, dietaryRestrictions: "" });
      setOpen(false);
    },
    [id, addGuest],
  );

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Box>
        <Dialog.Trigger asChild>
          <Button className={"clickable"}>
            <PlusIcon width={"16px"} height={"16px"} /> Add Guest
          </Button>
        </Dialog.Trigger>
      </Box>
      <GuestEditorContent
        titleText={"Add Guest"}
        onSubmit={handleSubmit}
        submitText={"Add Guest"}
      />
    </Dialog.Root>
  );
}
