import React, { useState, useEffect } from "react";
import TitleText from "./TitleText";
import { Flex } from "@radix-ui/themes";

const EVENT_DATE = 1718924400000; // new Date('2024-06-20 23:00Z').valueOf()
const NUMBER_FORMATTER = new Intl.NumberFormat(undefined, {
  minimumIntegerDigits: 2,
  maximumFractionDigits: 0,
});

export default function Countdown() {
  const [dateMs, setDateMs] = useState(Date.now());
  const [display, setDisplay] = useState(formatDuration(EVENT_DATE - dateMs));

  useEffect(() => {
    setDisplay(formatDuration(EVENT_DATE - Date.now()));

    const timeoutHandle = setTimeout(
      () => {
        setDateMs(Date.now());
      },
      1000 - (dateMs % 1000),
    );

    return () => clearTimeout(timeoutHandle);
  }, [dateMs]);

  return <TitleText align="center">{display}</TitleText>;
}

function formatDuration(duration) {
  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  duration = Math.round(duration / 1000);
  if (duration > 0) {
    days = Math.floor(duration / 24 / 3600);
    duration %= 24 * 3600;
    hours = Math.floor(duration / 3600);
    duration %= 3600;
    minutes = Math.floor(duration / 60);
    seconds = duration % 60;
  }

  return (
    <Flex gap={"5"}>
      <Flex justify={"end"} width={"1"} grow={"1"} asChild>
        <TitleText as={"span"}>
          {days} day{days === 1 ? " " : "s "}
        </TitleText>
      </Flex>
      <Flex width={"1"} grow={"1"} asChild>
        <TitleText as={"span"}>
          {NUMBER_FORMATTER.format(hours)}:{NUMBER_FORMATTER.format(minutes)}:
          {NUMBER_FORMATTER.format(seconds)}
        </TitleText>
      </Flex>
    </Flex>
  );
}
