import React from "react";

import { Callout as RadixCallout, Flex } from "@radix-ui/themes";
import {
  InfoCircledIcon,
  ExclamationTriangleIcon,
  CrossCircledIcon,
  CheckCircledIcon,
} from "@radix-ui/react-icons";

const VARIANT_ICONS = {
  info: InfoCircledIcon,
  warn: ExclamationTriangleIcon,
  error: CrossCircledIcon,
  success: CheckCircledIcon,
};

const VARIANT_COLORS = {
  info: "blue",
  warn: "yellow",
  error: "red",
  success: "green",
};

export default function Callout(props) {
  const { variant, themeVariant = "surface", children, ...restProps } = props;
  const Icon = VARIANT_ICONS[variant];

  return (
    <Flex align={"center"} asChild={true}>
      <RadixCallout.Root
        variant={themeVariant}
        size={"1"}
        color={VARIANT_COLORS[variant]}
        {...restProps}
      >
        {Icon && (
          <RadixCallout.Icon>
            <Icon width={"2em"} height={"2em"} />
          </RadixCallout.Icon>
        )}
        <RadixCallout.Text>{children}</RadixCallout.Text>
      </RadixCallout.Root>
    </Flex>
  );
}
