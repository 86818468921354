import { parseJsonResponse } from "./util";

export async function getSelf() {
  const response = await fetch(
    "https://api.helenandsolomon2024.com/parties/me",
    {
      method: "GET",
      credentials: "include",
    },
  );
  return parseJsonResponse(response);
}

export async function put(body) {
  const response = await fetch(
    `https://api.helenandsolomon2024.com/parties/${body.id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      credentials: "include",
    },
  );
  return parseJsonResponse(response);
}
