import React from "react";
import { Link } from "@radix-ui/themes";
import { NavLink as RouterNavLink } from "react-router-dom";

import SpacedText from "./SpacedText";

export default function NavLink({ to, onClick, children }) {
  return (
    <RouterNavLink to={to}>
      <Link asChild>
        <SpacedText weight={"bold"} onClick={onClick}>
          {children}
        </SpacedText>
      </Link>
    </RouterNavLink>
  );
}
