import { pick } from "lodash";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";

import "@radix-ui/themes/styles.css";
import "./index.css";
import Base from "./components/Base";
import ActivitiesPage from "./pages/ActivitiesPage";
import RsvpPage from "./pages/RsvpPage";
import SaveTheDatePage from "./pages/SaveTheDatePage";
import reportWebVitals from "./reportWebVitals";
import ErrorPage from "./pages/ErrorPage";
import LodgingPage from "./pages/LodgingPage";
import TravelPage from "./pages/TravelPage";
import FaqPage from "./pages/FaqPage";
import RequireLogin from "./components/RequireLogin";
import { PartyContextProvider } from "./components/PartyContext";

const ROUTES = [
  {
    path: "/",
    label: "Home",
    element: <SaveTheDatePage />,
  },
  {
    path: "travel",
    label: "Travel",
    element: <TravelPage />,
  },
  {
    path: "lodging",
    label: "Lodging",
    element: <LodgingPage />,
  },
  {
    path: "itinerary",
    label: "Itinerary",
    element: <ActivitiesPage />,
  },
  {
    path: "faq",
    label: "FAQ",
    element: <FaqPage />,
  },
  {
    path: "rsvp",
    label: "RSVP",
    element: (
      <RequireLogin>
        <RsvpPage />
      </RequireLogin>
    ),
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <BaseWithRoutes>
        <Outlet />
      </BaseWithRoutes>
    ),
    children: [
      ...ROUTES.map((route) => pick(route, "path", "element")),
      {
        path: "save-the-date",
        element: <Navigate to={"/"} />,
      },
    ],
    errorElement: (
      <BaseWithRoutes>
        <ErrorPage />
      </BaseWithRoutes>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function BaseWithRoutes({ children }) {
  return (
    <Base
      routes={ROUTES.map((route) => pick(route, "path", "label", "hidden"))}
    >
      <PartyContextProvider>{children}</PartyContextProvider>
    </Base>
  );
}
