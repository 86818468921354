import { parseJsonResponse } from "./util";

export async function post(body) {
  const response = await fetch("https://api.helenandsolomon2024.com/auth", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    credentials: "include",
  });
  return parseJsonResponse(response);
}
