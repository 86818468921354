import {
  Box,
  Button,
  Flex,
  Link,
  Section,
  VisuallyHidden,
} from "@radix-ui/themes";
import React, { useCallback, useRef, useState } from "react";
import * as yup from "yup";

import PaddedCard from "../components/PaddedCard";
import TitleText from "../components/TitleText";
import MinorText from "../components/MinorText";
import Text from "../components/Text";
import TextInput from "../components/TextInput";
import { post as postSubscribe } from "../api/subscribe.js";
import Callout from "../components/Callout";
import { useResetScrollOnMount } from "../components/ScrollArea";
import { NavLink } from "react-router-dom";
import Countdown from "../components/Countdown";

const FORM_VALIDATOR = yup
  .object({
    name: yup.string().required().trim(),
    email: yup.string().required().trim().email(),
  })
  .required()
  .noUnknown();

export default function SaveTheDatePage() {
  const [errorMessage, setErrorMessage] = useState("");
  const [hasSubscribed, setHasSubscribed] = useState(false);
  const [requestIsInProgress, setRequestIsInProgress] = useState(false);

  const formRef = useRef();
  useResetScrollOnMount();

  const submitSubscribeRequest = useCallback(async () => {
    try {
      setErrorMessage("");
      setRequestIsInProgress(true);
      const rawInputs = Object.fromEntries(
        new FormData(formRef.current).entries(),
      );

      const validatedInputs = await FORM_VALIDATOR.validate(rawInputs, {
        abortEarly: false,
      });
      await postSubscribe(validatedInputs);

      setHasSubscribed(true);
    } catch (error) {
      const message =
        error instanceof yup.ValidationError
          ? error.errors?.join("\n") || error.message
          : error.status === 400
            ? error.message
            : "An unexpected error occurred";

      setErrorMessage(message);
    }
    setRequestIsInProgress(false);
  }, []);

  const disableInputs = hasSubscribed || requestIsInProgress;

  return (
    <PaddedCard>
      <Section size="1">
        <TitleText align="center">See You Soon!</TitleText>
        <MinorText align="center">Thursday, 20 June 2024</MinorText>
        <MinorText align="center">South Lake Tahoe, CA</MinorText>
      </Section>
      <Section size="1">
        <Countdown />
      </Section>
      <Flex asChild gap={"3"} direction={"column"}>
        <Section size="1">
          <Text>
            <Link asChild>
              <NavLink to={"/rsvp"}>Click here to change your RSVP.</NavLink>
            </Link>
          </Text>
          <Text>
            Check out the other pages for information about travel, lodging,
            event schedule, and more. If you have any other questions, please
            don&apos;t hesitate to email us at{" "}
            <Link href={"mailto:info@helenandsolomon2024.com"}>
              info@helenandsolomon2024.com
            </Link>
            .
          </Text>
          <Text>
            If you&apos;d like to receive updates from us by email, please fill
            out the form below.
          </Text>
        </Section>
      </Flex>
      <Section size="1">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            submitSubscribeRequest();
          }}
          ref={formRef}
        >
          <Flex justify="center">
            <Flex
              direction="column"
              gap="3"
              align="stretch"
              grow="1"
              style={{ maxWidth: "400px" }}
            >
              <TextInput
                labelText="Name"
                inputName="name"
                placeholder="Enter your name"
                disabled={disableInputs}
              />
              <TextInput
                labelText="Email"
                inputName="email"
                placeholder="Enter your email"
                disabled={disableInputs}
              />
              {hasSubscribed ? (
                <Callout variant={"success"}>Submitted!</Callout>
              ) : (
                <Box>
                  <Button disabled={disableInputs}>
                    {disableInputs ? "Submitting..." : "Submit"}
                    <VisuallyHidden asChild={true}>
                      <input type="submit" />
                    </VisuallyHidden>
                  </Button>
                </Box>
              )}
              {errorMessage && (
                <Callout variant={"error"}>
                  <Text style={{ whiteSpace: "pre-wrap" }}>{errorMessage}</Text>
                </Callout>
              )}
            </Flex>
          </Flex>
        </form>
      </Section>
    </PaddedCard>
  );
}
