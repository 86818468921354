import {
  Theme,
  Container,
  Flex,
  Box,
  Section,
  IconButton,
  Callout,
} from "@radix-ui/themes";
import React, {
  useMemo,
  useState,
  useContext,
  createContext,
  useCallback,
  useRef,
  useEffect,
} from "react";
import TitleText from "./TitleText";
import { matchPath, useLocation } from "react-router-dom";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import NavLink from "./NavLink";
import * as Accordion from "@radix-ui/react-accordion";
import MinorText from "./MinorText";
import ScrollArea from "./ScrollArea";

const NavContext = createContext({});

export default function Base({ children, routes }) {
  const { pathname } = useLocation();
  const { label: pageName, path: currentPath } = useMemo(() => {
    return routes.find((r) => matchPath(r.path, pathname)) || {};
  }, [routes, pathname]);
  const [navOpen, setNavOpen] = useState(false);
  const closeNav = useCallback(() => {
    setNavOpen(false);
  }, []);

  /*
   * Close Nav when clicking outside
   */
  const staticNavRef = useRef(null);
  const scrollingNavRef = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      for (const ref of [staticNavRef, scrollingNavRef]) {
        if (ref.current?.contains?.(event.target)) {
          return;
        }
      }
      closeNav();
    },
    [closeNav],
  );

  useEffect(() => {
    if (!navOpen) return;

    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [navOpen, handleClickOutside]);

  return (
    <NavContext.Provider
      value={{ routes, pageName, currentPath, navOpen, setNavOpen, closeNav }}
    >
      <Theme asChild>
        <Container size={"4"} height={"100%"}>
          <Flex direction={"column"} width={"100%"} height={"100%"}>
            <Banner className={"staticBanner"} navRef={staticNavRef} />
            <Flex grow={"1"} shrink={"1"} style={{ minHeight: 0 }}>
              <VerticalNav />
              <Box grow={"1"} shrink={"1"}>
                <ScrollArea>
                  <Banner
                    routes={routes}
                    className={"scrollingBanner"}
                    pageName={pageName}
                    navRef={scrollingNavRef}
                  />
                  <Flex
                    direction={"column"}
                    grow={"1"}
                    shrink={"1"}
                    align={"center"}
                    justify={"center"}
                    gap={"3"}
                  >
                    {children}
                  </Flex>
                  <Section size={"1"}>
                    <Flex justify={"center"}>
                      <MinorText>Solomon Liu &copy; 2024</MinorText>
                    </Flex>
                  </Section>
                </ScrollArea>
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Theme>
    </NavContext.Provider>
  );
}

function Banner({ className, navRef }) {
  return (
    <Section className={className} size={"1"}>
      <NarrowBanner navRef={navRef} />
      <WideBanner />
    </Section>
  );
}

function WideBanner() {
  return (
    <Flex
      className={"wideBanner"}
      direction={"column"}
      align={"center"}
      gap={"3"}
    >
      <TitleText>Helen & Solomon</TitleText>
      <Flex
        className={"wideBannerNav"}
        align={"center"}
        justify={"center"}
        gap={"9"}
      >
        <NavLinks />
      </Flex>
    </Flex>
  );
}

function NarrowBanner({ navRef }) {
  const { pageName, navOpen, setNavOpen } = useContext(NavContext);

  return (
    <Accordion.Root
      type={"single"}
      collapsible
      className={"narrowBanner"}
      value={navOpen && "nav"}
      onValueChange={(value) => setNavOpen(value === "nav")}
      ref={navRef}
    >
      <Accordion.Item value={"nav"} asChild>
        <Flex direction={"column"} gap={"3"}>
          <Flex gap={"3"} p={"3"} align={"center"}>
            <Accordion.Trigger asChild>
              <IconButton size={"4"} variant={"ghost"} ml={"3"} mr={"3"}>
                <HamburgerMenuIcon width={"2em"} height={"2em"} />
              </IconButton>
            </Accordion.Trigger>
            <Flex direction={"column"}>
              <TitleText>H&S</TitleText>
              <Box height={"0"}>
                <MinorText>{pageName}</MinorText>
              </Box>
            </Flex>
          </Flex>
          <Accordion.Content asChild>
            <Flex
              gap={"3"}
              wrap={"wrap"}
              width={"100%"}
              pl={"3"}
              pr={"3"}
              justify={"between"}
              className={"navAccordionContent"}
            >
              <NavLinks />
            </Flex>
          </Accordion.Content>
        </Flex>
      </Accordion.Item>
    </Accordion.Root>
  );
}

function NavLinks() {
  const { routes, currentPath, closeNav } = useContext(NavContext);

  return (
    <>
      {routes.map(({ label, path, hidden }) => {
        if (hidden) return null;

        return (
          label && (
            <Callout.Root
              key={path}
              size="1"
              style={{
                backgroundColor:
                  currentPath === path ? undefined : "transparent",
              }}
            >
              <Callout.Text asChild>
                <NavLink to={path} onClick={closeNav}>
                  {label}
                </NavLink>
              </Callout.Text>
            </Callout.Root>
          )
        );
      })}
    </>
  );
}

function VerticalNav() {
  return (
    <Box className={"leftNav"} p={"3"}>
      <ScrollArea>
        <Flex
          direction={"column"}
          grow={"1"}
          shrink={"1"}
          justify={"center"}
          align={"end"}
        >
          <NavLinks />
        </Flex>
      </ScrollArea>
    </Box>
  );
}
