import React from "react";
import { Box, Flex } from "@radix-ui/themes";

const FloatLayout = {
  Root: FloatLayoutRoot,
  Float: FloatLayoutFloat,
};
export default FloatLayout;

function FloatLayoutRoot({ children, ...otherProps }) {
  return (
    <Box className="floatLayoutRoot" {...otherProps}>
      {children}
    </Box>
  );
}

function FloatLayoutFloat({ children, float, style, ...otherProps }) {
  style = { float, ...style };

  return (
    <Flex
      className="floatLayoutFloat"
      justify={"center"}
      style={style}
      {...otherProps}
    >
      <Box style={{ maxWidth: "min(100%, 400px)" }} asChild>
        {children}
      </Box>
    </Flex>
  );
}
