import React from "react";

import { Flex, Link, Section } from "@radix-ui/themes";
import SpacedText from "../components/SpacedText";
import TitleText from "../components/TitleText";
import { useResetScrollOnMount } from "../components/ScrollArea";

export default function ActivitiesPage() {
  useResetScrollOnMount();

  return (
    <Flex grow={"1"} direction={"column"} width={"100%"} gap={"3"} p={"3"}>
      <Section size={"1"}>
        <TitleText>Wednesday</TitleText>
        <SpacedText>June 19</SpacedText>
        <br />
        Helen and Solomon drive up from the Bay Area. Let us know if you&apos;re
        around; We&apos;d love to meet up!
      </Section>
      <Section size={"1"}>
        <TitleText>Thursday</TitleText>
        <SpacedText>June 20</SpacedText>
        <br />
        The big day at{" "}
        <Link target="_blank" href="https://maps.app.goo.gl/mA9fwZuj1CVKdEzf8">
          Valhalla Tahoe
        </Link>
        !
        <br />
        <br />
        16:00 Ceremony starts; Cocktails, hors d&apos;oeuvres, photos to follow
        <br />
        18:00 Dinner; Dancing to follow
        <br />
        20:00 Sunset photos
        <br />
        22:00 Festivities end
      </Section>
      <Section size={"1"}>
        <TitleText>Friday</TitleText>
        <SpacedText>June 21</SpacedText>
        <br />
        Beach day, if weather permits. Let us know if you&apos;d like to hang
        out!
        <br />
      </Section>
      <Section size={"1"}>
        <TitleText>Saturday</TitleText>
        <SpacedText>June 22</SpacedText>
        <br />
        Helen and Solomon drive back to the Bay Area.
      </Section>
    </Flex>
  );
}
