import React from "react";

import MinorText from "./MinorText";
import { Flex, TextField } from "@radix-ui/themes";

export default function TextInput(props) {
  const {
    labelText,
    inputName,
    placeholder,
    disabled,
    onChange,
    value,
    defaultValue,
  } = props;

  return (
    <Flex direction="column" gap="1" asChild={true}>
      <MinorText as="label">
        {labelText}
        <TextField.Input
          placeholder={placeholder}
          name={inputName}
          disabled={disabled}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
        />
      </MinorText>
    </Flex>
  );
}
