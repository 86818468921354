import React, { useCallback, useContext, useEffect, useState } from "react";
import PaddedCard from "./PaddedCard";
import TitleText from "./TitleText";
import Text from "./Text";
import { Button, Flex, Link, VisuallyHidden } from "@radix-ui/themes";
import TextInput from "./TextInput";
import { PartyContext } from "./PartyContext";
import { post as postAuth } from "../api/auth";

export default function RequireLogin({ children }) {
  const { isLoggedIn, fetchParty } = useContext(PartyContext);

  useEffect(() => {
    fetchParty();
  }, [fetchParty]);

  return isLoggedIn ? (
    <>{children}</>
  ) : (
    <LoginCard isLoading={isLoggedIn == null} />
  );
}

function LoginCard({ isLoading }) {
  const { fetchParty } = useContext(PartyContext);

  const [isAuthInProgress, setIsAuthInProgress] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [error, setError] = useState(null);

  const handleCodeChange = useCallback((event) => {
    setCanSubmit(!!event.target.value);
  }, []);

  const handleAuth = useCallback(
    async (event) => {
      event.preventDefault();

      const { code: authToken } = Object.fromEntries(
        new FormData(event.target).entries(),
      );

      setIsAuthInProgress(true);
      setError(null);
      try {
        await postAuth({ authToken });
        fetchParty();
      } catch (error) {
        if (error instanceof TypeError) {
          setError(
            <Text color={"red"} size={"1"}>
              You seem to be offline. Please check internet connection and try
              again, or email{" "}
              <Link href={"mailto:support@helenandsolomon2024.com"}>
                support@helenandsolomon2024.com
              </Link>{" "}
              for help.
            </Text>,
          );
        } else if (error.status === 401) {
          setError(
            <Text color={"red"} size={"1"}>
              Hmm, that didn&apos;t work. Please check your code and try again,
              or email{" "}
              <Link href={"mailto:support@helenandsolomon2024.com"}>
                support@helenandsolomon2024.com
              </Link>{" "}
              for help.
            </Text>,
          );
        } else {
          setError(
            <Text color={"red"} size={"1"}>
              Something unexpected happened. Please try again later, or email{" "}
              <Link href={"mailto:support@helenandsolomon2024.com"}>
                support@helenandsolomon2024.com
              </Link>{" "}
              for help.
            </Text>,
          );
        }
      } finally {
        setIsAuthInProgress(false);
      }
    },
    [fetchParty],
  );

  return (
    <Flex justify={"center"}>
      <PaddedCard style={{ flexBasis: "456px" }} grow={"0"}>
        <Flex direction={"column"} gap={"3"}>
          <TitleText align="center">RSVP Login</TitleText>
          <Text align="center">
            {isLoading
              ? "Loading..."
              : "Please enter the code included with your invitation"}
          </Text>
          {!isLoading && (
            <Flex gap={"1"} justify={"center"} asChild>
              <form onSubmit={handleAuth}>
                <TextInput
                  disabled={isAuthInProgress}
                  placeholder={"Enter code"}
                  inputName={"code"}
                  onChange={handleCodeChange}
                />
                <Button
                  disabled={isAuthInProgress || !canSubmit}
                  className={"clickable"}
                >
                  Go
                  <VisuallyHidden asChild={true}>
                    <input type="submit" tabIndex={-1} />
                  </VisuallyHidden>
                </Button>
              </form>
            </Flex>
          )}
          {error}
        </Flex>
      </PaddedCard>
    </Flex>
  );
}
