import React from "react";

import Text from "../components/Text";
import { Flex, Link, Section, Strong } from "@radix-ui/themes";
import TitleText from "../components/TitleText";
import FloatLayout from "../components/FloatLayout";
import { useResetScrollOnMount } from "../components/ScrollArea";

const beachRetreatImg = require("../assets/beach-retreat.jpg");

export default function LodgingPage() {
  useResetScrollOnMount();

  return (
    <Flex p={"3"} grow={"1"} direction={"column"}>
      <Flex direction={"column"} gap={"3"} asChild>
        <Section size={"1"}>
          <TitleText align={"center"}>Beach Retreat</TitleText>
          <FloatLayout.Root>
            <FloatLayout.Float float={"right"}>
              <img src={beachRetreatImg} alt="Beach Retreat" />
            </FloatLayout.Float>
            <Text>
              We will be staying at&nbsp;
              <Link href="https://www.tahoebeachretreat.com">
                Beach Retreat and Lodge
              </Link>
              , and have reserved a room block for anyone who would like to stay
              there too. Use group code <Strong>GLW24</Strong> for a discount on
              1-3 night stays between June 19 and 22, 2024.
            </Text>
            <Text>
              The group code should apply to &quot;Standard Garden View One
              King&quot; and &quot;Standard Garden View Two Queen&quot; rooms.
              There are a set number of rooms of each type, so please let us
              know if the room type you want isn&apos;t available; we&apos;re
              happy to see if the hotel can adjust the allocation.
            </Text>
            <Text>
              If you&apos;re interested in an upgraded room, or if you want to
              stay beyond the dates above, please let us know as well; we might
              be able to get you a discount!
            </Text>
            <Text>
              The group code expires on May 19, 2024; please book before then!
            </Text>
          </FloatLayout.Root>
        </Section>
      </Flex>
      <Flex direction={"column"} gap={"3"} asChild>
        <Section size={"1"}>
          <TitleText align={"center"}>Other Options</TitleText>
          <Text>
            If Beach Retreat does not suit your needs, we recommend looking
            around the CA-NV state line. There are a variety of options close to
            the beach, restaurants, bars, casinos and other amenities.
          </Text>
          <Text>
            There are also an abundance of options via websites such as Airbnb
            and Vrbo.
          </Text>
          <Text>
            We are offering door-to-door shuttles to and from the venue. If you
            are interesting in using them, we ask that you choose lodging within
            the South Lake Tahoe and Stateline city limits, to simplify
            coordinating pickups and dropoffs. We&apos;ll do our best to
            accommodate locations outside those limits.
          </Text>
        </Section>
      </Flex>
    </Flex>
  );
}
