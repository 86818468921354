import React from "react";

import Text from "./Text";

export default function SpacedText({ children, style, ...props }) {
  return (
    <Text
      style={{ textTransform: "uppercase", letterSpacing: ".375em", ...style }}
      {...props}
    >
      {children}
    </Text>
  );
}
