import React, {
  createContext,
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from "react";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import {
  Flex,
  Callout,
  Portal,
  Dialog,
  DialogContent,
  Button,
  Link,
} from "@radix-ui/themes";

import Text from "./Text";
import {
  CheckCircledIcon,
  ExclamationTriangleIcon,
  Pencil1Icon,
  UploadIcon,
} from "@radix-ui/react-icons";

import { getSelf, put as putParty } from "../api/parties";

export const PartyContext = createContext(null);
export function PartyContextProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [party, setParty] = useState(null);
  const [sentParty, setSentParty] = useState(null);
  const [savedParty, setSavedParty] = useState(null);
  const [saveError, setSaveError] = useState(null);
  const [showRetry, setShowRetry] = useState(false);
  const nextGuestId = useRef(1);

  const fetchParty = useCallback(async () => {
    setIsLoggedIn(null);

    try {
      // uncomment for ease of testing
      // const fetchedParty = {
      //   id: 1,
      //   guests: [],
      //   maxGuests: 2,
      //   shuttleOptions: {
      //     pickUpLocation: "",
      //     dropOffLocation: "",
      //   },
      //   otherAccommodations: "",
      // };
      const fetchedParty = await getSelf();
      setIsLoggedIn(true);
      fetchedParty.guests.forEach((guest) => {
        guest.id = nextGuestId.current++;
      });
      setParty(fetchedParty);
      setSentParty(fetchedParty);
      setSavedParty(fetchedParty);
    } catch (error) {
      setIsLoggedIn(false);
    }
  }, []);

  const updateParty = useCallback((update) => {
    setParty((party) => {
      if (!update.id || update.id !== party.id) return party;

      const updatedParty = {
        ...party,
        ...update,
      };

      if (isEqual(party, updatedParty)) return party;

      return updatedParty;
    });
  }, []);

  const addGuest = useCallback((partyId, guest) => {
    setParty((party) => {
      if (!partyId || partyId !== party.id) return party;

      return {
        ...party,
        guests: [
          ...party.guests,
          {
            ...guest,
            id: nextGuestId.current++,
          },
        ],
      };
    });
  }, []);

  const removeGuest = useCallback((guestId) => {
    setParty((party) => {
      const guestIndex = party?.guests.findIndex(
        (guest) => guest.id === guestId,
      );
      if (guestIndex === -1) return party;

      return {
        ...party,
        guests: [
          ...party.guests.slice(0, guestIndex),
          ...party.guests.slice(guestIndex + 1),
        ],
      };
    });
  }, []);

  const updateGuest = useCallback((update) => {
    setParty((party) => {
      const guestIndex = party?.guests.findIndex(
        (guest) => guest.id === update.id,
      );
      if (guestIndex === -1) return party;

      const guest = party.guests[guestIndex];

      return {
        ...party,
        guests: [
          ...party.guests.slice(0, guestIndex),
          {
            ...guest,
            ...update,
          },
          ...party.guests.slice(guestIndex + 1),
        ],
      };
    });
  }, []);

  const openRetryDialog = useCallback(() => {
    if (saveError) setShowRetry(true);
  }, [saveError]);

  const handleSaveParty = useCallback(async () => {
    const partyData = {
      ...party,
      guests: party.guests.map((guest) => omit(guest, "id")),
    };
    try {
      setSentParty(party);
      setSaveError(null);
      setShowRetry(false);
      // comment putParty and uncomment console.log for testing
      await putParty(partyData);
      // console.log("sending", partyData);
      // throw new Error();
      setSavedParty(party);
    } catch (error) {
      setSaveError(error);
    }
  }, [party]);

  useEffect(() => {
    if (isEqual(party, sentParty)) return;

    const timeoutHandle = setTimeout(async () => {
      handleSaveParty();
    }, 500);

    return () => {
      clearInterval(timeoutHandle);
    };
  }, [party, sentParty, handleSaveParty]);

  const isSent = useMemo(() => isEqual(party, sentParty), [party, sentParty]);
  const isSaved = useMemo(
    () => isEqual(party, sentParty) && isEqual(sentParty, savedParty),
    [party, sentParty, savedParty],
  );

  return (
    <PartyContext.Provider
      value={{
        isLoggedIn,
        party,
        fetchParty,
        updateParty,
        addGuest,
        updateGuest,
        removeGuest,
        saveError,
      }}
    >
      {children}
      {isLoggedIn && party && (
        <Portal
          asChild
          container={document.querySelector(".rt-ContainerInner")}
        >
          <Flex
            position={"absolute"}
            right={"0"}
            top={"0"}
            m={"3"}
            align={"center"}
            asChild
          >
            <Callout.Root
              color={isSaved ? "green" : saveError ? "red" : "gray"}
              size={"1"}
              variant={"surface"}
              className={saveError ? "clickable" : ""}
              onClick={openRetryDialog}
            >
              <Callout.Icon>
                {isSaved ? (
                  <CheckCircledIcon width={"16px"} height={"16px"} />
                ) : saveError ? (
                  <ExclamationTriangleIcon width={"16px"} height={"16px"} />
                ) : isSent ? (
                  <UploadIcon width={"16px"} height={"16px"} />
                ) : (
                  <Pencil1Icon width={"16px"} height={"16px"} />
                )}
              </Callout.Icon>
              <Callout.Text>
                <Text>
                  {isSaved
                    ? "Saved"
                    : saveError
                      ? "Error Saving"
                      : isSent
                        ? "Saving..."
                        : "Modified"}
                </Text>
              </Callout.Text>
            </Callout.Root>
          </Flex>
        </Portal>
      )}
      {saveError && (
        <Dialog.Root open={showRetry} onOpenChange={setShowRetry}>
          <DialogContent>
            <Dialog.Title>Retry Save</Dialog.Title>
            <Flex direction={"column"} gap={"3"}>
              <Text>
                {saveError instanceof TypeError
                  ? "You appear to be offline. Please check your internet connection and retry."
                  : "Something unexpected happened while saving your work."}
              </Text>
              <Text>
                If issues persist, please email{" "}
                <Link href={"support@helenandsolomon2024.com"}>
                  support@helenandsolomon2024.com
                </Link>{" "}
                for help.
              </Text>
              <Flex gap={"3"}>
                <Button onClick={handleSaveParty}>Retry</Button>
                <Dialog.Close asChild>
                  <Button variant={"outline"}>Close</Button>
                </Dialog.Close>
              </Flex>
            </Flex>
          </DialogContent>
        </Dialog.Root>
      )}
    </PartyContext.Provider>
  );
}
