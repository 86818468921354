import React from "react";
import SpacedText from "./SpacedText";

export default function MinorText({ children, ...props }) {
  return (
    <SpacedText size="1" weight="light" {...props}>
      {children}
    </SpacedText>
  );
}
