import React from "react";

import { Card, Box } from "@radix-ui/themes";

export default function PaddedCard({ children, ...props }) {
  return (
    <Card asChild={true} m="3">
      <Box p="3" {...props}>
        {children}
      </Box>
    </Card>
  );
}
