import React from "react";

import { Flex, Link, Section } from "@radix-ui/themes";
import TitleText from "../components/TitleText";
import Text from "../components/Text";
import SpacedText from "../components/SpacedText";
import { useResetScrollOnMount } from "../components/ScrollArea";

export default function TravelPage() {
  useResetScrollOnMount();

  return (
    <Flex p={"3"} grow={"1"} direction={"column"} width={"100%"}>
      <Flex direction={"column"} gap={"3"} asChild>
        <Section size={"1"}>
          <TitleText align={"center"}>Flights</TitleText>
          <Text>
            The closest major airport is Reno-Tahoe International Airport (RNO),
            about 1h - 1h40m drive to South Lake Tahoe. If you plan to do any
            sightseeing outside of South Lake Tahoe city proper, renting a car
            will probably be your best option. If you don&apos;t plan on leaving
            the city, consider saving some money by taking&nbsp;
            <Link target="_blank" href="https://southtahoeairporter.com/">
              South Tahoe Airporter
            </Link>
            .
          </Text>
        </Section>
      </Flex>
      <Flex direction={"column"} gap={"3"} asChild>
        <Section size={"1"}>
          <TitleText align={"center"}>Getting Around</TitleText>
          <SubSection>
            <SubTitle>Driving</SubTitle>
            <Text>
              If you plan to do any sightseeing outside of the city proper,
              driving will probably be your best option. Most roads to popular
              sightseeing spots in the area are winding, with one lane each way.
              Please drive carefully!
            </Text>
            <Text>
              If you are travelling with children, please be aware that&nbsp;
              <Link
                target="_blank"
                href="https://www.ots.ca.gov/child-passenger-safety/"
              >
                California has car seat laws
              </Link>
              .
            </Text>
          </SubSection>
          <SubSection>
            <SubTitle>Lake Link</SubTitle>
            <Text>
              <Link target="_blank" href="https://ss-tma.org/lake-link/">
                Lake Link
              </Link>
              &nbsp;is a free, on-demand microtransit service operating in a
              limited area in South Lake Tahoe and Stateline. It works similarly
              to Lyft or Uber&apos;s shared rides; Request through the app, and
              you&apos;ll share a vehicle with other passengers along your
              route.
            </Text>
          </SubSection>
          <SubSection>
            <SubTitle>Rideshare</SubTitle>
            <Text>
              Lyft and Uber are both available in South Lake Tahoe, for places
              that Lake Link doesn&apos;t serve.
            </Text>
            <Text>
              If you are travelling with children, please be aware that&nbsp;
              <Link
                target="_blank"
                href="https://www.ots.ca.gov/child-passenger-safety/"
              >
                California car seat laws
              </Link>
              &nbsp; apply to taxis and rideshares as well.
            </Text>
          </SubSection>
          <SubSection>
            <SubTitle>Micromobility</SubTitle>
            <Text>
              Some points of interests can be reached via mixed-used paths that
              are separated from motor vehicle traffic. The transit agency
              maintains&nbsp;
              <Link href="https://map.tahoebike.org" target="_blank">
                a map of bike paths
              </Link>
              . You can rent a bike, surrey, or scooter from one of the many
              local bike shops, or by using the Lime app.
            </Text>
            <Text>
              Please exercise caution when using the &quot;bike lanes&quot; and
              &quot;bike routes&quot; indicated on the map; These are not
              physically separated from motor vehicle traffic.
            </Text>
          </SubSection>
        </Section>
      </Flex>
    </Flex>
  );
}

function SubSection({ children }) {
  return (
    <Section size={"1"}>
      <Flex direction={"column"} gap={"3"} width={"100%"}>
        {children}
      </Flex>
    </Section>
  );
}

function SubTitle({ children }) {
  return <SpacedText weight={"bold"}>{children}</SpacedText>;
}
