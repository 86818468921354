import React from "react";

import SpacedText from "./SpacedText";

export default function TitleText({ children, ...props }) {
  return (
    <SpacedText size="7" {...props}>
      {children}
    </SpacedText>
  );
}
