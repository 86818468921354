import React from "react";

import { Link, useRouteError } from "react-router-dom";

import TitleText from "../components/TitleText";
import { Button, Flex, Section } from "@radix-ui/themes";
import MinorText from "../components/MinorText";

const ERROR_MESSAGES = {
  401: {
    title: "Halt! Who goes there?",
    message: "Please sign in and and try again.",
  },
  403: {
    title: "You shall not pass!",
    message: "You do not have permission to see this page.",
  },
  404: {
    title: "Nothing to see here",
    message: `This page doesn't exist.`,
  },
  500: {
    title: "Oops...",
    message: "Something unexpected happened.",
    refresh: true,
  },
};

export default function ErrorPage() {
  const error = useRouteError();

  const pageData = ERROR_MESSAGES[error.status] || ERROR_MESSAGES[500];

  return (
    <>
      <Section size="1">
        <TitleText align="center">{pageData.title}</TitleText>
        <MinorText align="center">{pageData.message}</MinorText>
      </Section>
      <Section size="1">
        <Flex gap="3" justify="center">
          <Button onClick={() => window.history.back()}>Go Back</Button>
          <Link to={"/"}>
            <Button>Go Home</Button>
          </Link>
          {pageData.refresh && (
            <Button onClick={() => window.location.reload()}>Reload</Button>
          )}
        </Flex>
      </Section>
    </>
  );
}
